*,
::before,
::after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    height: 100vh;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}

h1, h2, h3, h4, h5, h6,
title,
a,
p,
span,
li {
    font-family: 'Montserrat';
    color: rgb(241, 241, 241);
    font-weight: 400;
}

span{
    color: rgb(133, 133, 133);
}