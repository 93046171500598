.loading{
    margin: 1rem auto;
    height: 40px;
    filter: contrast(20);
    background-color: rgb(20, 20, 20);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    span{
        color: red;
    }
    
    .circle{
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 50%;
        margin: 0 10px;
        filter: blur(5px);
    }
    .circle:nth-child(1){
        animation: moveCircleX 3s linear infinite;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    @keyframes moveCircleX {
        0%{
            transform: translateX(-100px);
        }
        50%{
            transform: translateX(60px);
        }
        100%{
            transform: translateX(-100px);
        }
    }
}