.inprogress{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

@media screen and (max-width: 425px){
    .inprogress{
        align-items: center;
    }
    h1{
        font-size: 1rem;
    }
    span{
        font-size: 1rem;
    }
}